<template>
  <div class="bordered-box" :class="{ 'border-around': hasBorderAround }" :style="`--background: ${background}; --borderColor: ${borderColor}; --borderWidth: ${borderWidth}px; --boxShadow: ${boxShadow}`">
    <h1 v-if="title" class="title" v-html="title" />
    <div class="description-wrapper">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BorderedBox",
  props: {
    title: {
      type: String,
      required: false,
    },
    background: {
      type: String,
      required: false,
      default: "#fff"
    },
    borderColor: {
      type: String,
      required: false,
      default: "#EA536F"
    },
    borderWidth: {
      type: Number,
      required: false,
      default: 15
    },
    boxShadow: {
      type: String,
      required: false,
      default: "0px 4px 8px rgba(0, 0, 0, 0.1)"
    },
    hasBorderAround: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.bordered-box {
  background: var(--background);
  border-radius: $border-radius-global;
  width: 100%;
  height: auto;
  padding: 35px 25px 30px 20px;
  border-left: 15px solid var(--borderColor);
  box-shadow: var(--boxShadow);

  @media (max-width: $breakpoint-md) {
    border-left: 10px solid var(--borderColor);
    padding: 25px 10px;
  }

  &.border-around {
    border-top: 1px solid var(--borderColor);
    border-right: 1px solid var(--borderColor);
    border-bottom: 1px solid var(--borderColor);
  }
}

.title {
  margin: 0 0 30px;
  font-size: 30px;
  line-height: 1;
  font-family: "HKNova-Bold", sans-serif;
  color: $dark-blue;
}
</style>