<template>
  <div class="warning-section" :style="`--backgroundColor: ${backgroundColor}`">
    <div class="container d-md-flex align-items-center">
      <div class="description-wrapper">
        <p v-if="firstDescription">{{ $t(firstDescription) }}</p>
        <p v-if="secondDescription" class="description-2">{{ $t(secondDescription) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WarningSection",
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: "#242A55"
    },
    firstDescription: {
      type: String,
      required: false,
      default: 'bit20Screen.warning.description_1'
    },
    secondDescription: {
      type: String,
      required: false,
      default: 'bit20Screen.warning.description_2'
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.warning-section {
  background-color: var(--backgroundColor);
  color: $white;
  padding: 75px 0;
  font-size: 14px;
  line-height: 30px;

  @media (max-width: $breakpoint-sm) {
    line-height: 24px;
  }
}

.description-wrapper {
    text-align: center;

  p {
    margin: 0 0 40px;

    &:last-child {
      margin: 0;
    }
  }
  .description-2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
  }
}
</style>