<template>
  <div class="card" :style="`--borderColor: ${borderColor}; --shadow: ${shadow}; --backgroundColor: ${backgroundColor}; --titleColor: ${titleColor}; --descriptionColor: ${descriptionColor}`" @click="handleClick(link)">
    <img v-if="thumbnailUrl" :src="thumbnailUrl" class="thumbnail">
    <div class="card-body">
      <span v-if="showTitle" class="title">{{ title }}</span>
      <span class="description">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    showTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
    },
    thumbnailUrl: {
      type: String,
      required: false,
    },
    borderColor: {
      type: String,
      required: false,
      default: "#DBD5EE"
    },
    shadow: {
      type: String,
      required: false,
      default: "0px 4px 8px 0px rgba(75, 46, 170, 0.1)"
    },
    link: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fff"
    },
    titleColor: {
      type: String,
      required: false,
      default: "#1B1B1B"
    },
    descriptionColor: {
      type: String,
      required: false,
      default: "#46668F"
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleClick(link) {
      window.open(link);
    }
  }
}
</script>
 
<style lang='scss' scoped>
.card {
  border-radius: $border-radius-global;
  border: 1px solid var(--borderColor);
  box-shadow: var(--shadow);
  overflow: hidden;
  transition: $transition-global;
  background-color: var(--backgroundColor);

  &:hover {
    cursor: pointer;
    box-shadow: none;
  }
}

.card-body {
  padding: 30px 15px;
}

.thumbnail {
  height: 155px;
  width: 100%;
  object-fit: cover;
}

.title {
  display: block;
  font-size: 30px;
  line-height: 1;
  margin: 0 0 20px;
  font-family: "HKNova-Bold", sans-serif;
  color: var(--titleColor);
}

.description {
  font-size: 14px;
  line-height: 24px;
  color: $text-blue;
  color: var(--descriptionColor);
}
</style>