<template>
  <div class="other-products" :style="`--titleColor: ${titleColor}`">
    <div class="container">
      <h1 class="section-title headline-l">{{ $t("globals.otherProducts.title") }}</h1>
      <div v-if="!showCarousel" class="products-wrapper">
        <template v-for="(item, i) in products" >
          <card
            v-if="hiddenProductKey != item.key"
            :key="i"
            :title="$t(item.title)"
            :description="$t(item.description)"
            :thumbnailUrl="item.thumbnail"
            :link="item.link"
            :borderColor="cardBorderColor"
            :shadow="cardShadow"
            :backgroundColor="cardBackgroundColor"
            :titleColor="cardTitleColor"
            :descriptionColor="cardDescriptionColor"
          />
        </template>
      </div>
      <carousel v-else :responsive="owlConfig">
        <template v-for="(item, i) in products" >
          <card
            v-if="hiddenProductKey != item.key"
            :key="i"
            :title="$t(item.title)"
            :description="$t(item.description)"
            :thumbnailUrl="item.thumbnail"
            :link="item.link"
            :borderColor="cardBorderColor"
            :shadow="cardShadow"
            :backgroundColor="cardBackgroundColor"
            :titleColor="cardTitleColor"
            :descriptionColor="cardDescriptionColor"
          />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import Card from "@/components/modules/products/Card";
import carousel from 'vue-owl-carousel'

export default {
  name: "OtherProducts",
  props: {
    titleColor: {
      type: String,
      required: false,
      default: "#262A52"
    },
    hiddenProductKey: {
      type: String,
      required: false
    },
    cardBorderColor: {
      type: String,
      required: false,
    },
    cardShadow: {
      type: String,
      required: false,
    },
    cardBackgroundColor: {
      type: String,
      required: false,
    },
    cardTitleColor: {
      type: String,
      required: false,
    },
    cardDescriptionColor: {
      type: String,
      required: false,
    }
  },
  components: {
    Card,
    carousel
  },
  data: () => ({
    showCarousel: false,
    owlConfig: {
      0: {
        items: 1,
        nav: false,
        dots: false,
        margin: 40,
        stagePadding: 100,
        center: true,
        startPosition: 1
      },
      575: {
        items: 2,
        nav: false,
        dots: false,
        margin: 40,
        stagePadding: 80,
        startPosition: 1
      },
      767: {
        items: 3,
        nav: false,
        dots: false,
        margin: 40,
        stagePadding: 80,
        startPosition: 0
      }
    },
    products: [
      {
        key: "ebso",
        title: "globals.otherProducts.ebso.title",
        description: "globals.otherProducts.ebso.description",
        link: "/products/ebso",
        thumbnail: "/images/sections/products/ebso.svg"
      },
      {
        key: "bno",
        title: "globals.otherProducts.bno.title",
        description: "globals.otherProducts.bno.description",
        link: "/products/bno",
        thumbnail: "/images/sections/products/bno.svg"
      },
      {
        key: "bnox",
        title: "globals.otherProducts.bnox.title",
        description: "globals.otherProducts.bnox.description",
        link: "/products/bnox",
        thumbnail: "/images/sections/products/bnox.svg"
      },
      {
        key: "natrix",
        title: "globals.otherProducts.natrix.title",
        description: "globals.otherProducts.natrix.description",
        link: "https://natrix.io/",
        thumbnail: "/images/sections/products/natrix.svg"
      },
    ]
  }),
  created() {
    if (window.innerWidth <= 991.98) {
      this.showCarousel = true;
    } else {
      this.showCarousel = false;
    }
    
    window.addEventListener('resize', (e) => {
      if (window.innerWidth <= 991.98) {
        this.showCarousel = true;
      } else {
        this.showCarousel = false;
      }
    });
  },
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
.other-products {
  padding: 60px 0 100px;

  @media (max-width: $breakpoint-xs) {
    .container {
      padding: 0;
    }
  }

  .container {
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      padding: 0;
    }
  }

  &::v-deep {
    .owl-stage {
      padding-bottom: 10px;
      display: flex;

      @media (max-width: $breakpoint-md) {
        .card {
          height: 100%;
        }
      }
    }
  }
}

.section-title {
  text-align: center;
  margin: 0 0 70px;
  color: var(--titleColor);

  @media (max-width: $breakpoint-xs) {
    padding: 0 15px;
  }
}

.products-wrapper {
  display: grid;
  grid-auto-flow: column;
  column-gap: 40px;
  padding: 0 110px;

  @media (max-width: $breakpoint-lg) {
    padding: 0;
  }
}
</style>